@import '../../common/style/styleguide';

.of-openform {
  $disabled-border-color: var(--eds-color-grey-400);
  $disabled-color: var(--eds-color-grey-500);
  $disabled-filter: grayscale(1);
  $top-bar-height: calc(var(--eds-baseline) * 5);
  $transition-time: 0.2s;

  &-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2px;

    input[type='checkbox'] {
      cursor: pointer;
      margin: 0 calc(var(--eds-baseline)) 0 calc(var(--eds-baseline) * 0.375);
      width: calc(var(--eds-baseline) * 2.5);
      height: calc(var(--eds-baseline) * 2.5);
    }
  }

  .ds-checkbox .ds-checkbox__label .ds-checkbox__icon svg {
    width: 100%;
    height: 100%;
    left: 0;
  }

  .ds-combobox {
    .ds-combobox__listbox {
      max-width: 100%;

      .ds-combobox__options {
        max-height: $breakpoint-s;
      }
    }

    .billing-account__label-container {
      margin-bottom: calc(var(--eds-baseline));

      .billing-account__label-h5 {
        margin: 0;
        padding: 0;
      }
    }
  }

  &.ds-grid__col--12,
  &__block.ds-grid__col--12 {
    padding: 0;
  }

  &__address-check {
    &.ds-grid__row {
      margin-top: calc(var(--eds-baseline) * 8);
      padding: calc(var(--eds-baseline) * 2);

      .ds-grid__col--4,
      .ds-grid__col--5 {
        padding: calc(var(--eds-baseline) * 5);
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .of-address-search__postal-code,
    .of-postal-code__wrapper,
    .of-search__container {
      width: 100%;
      padding: 0;
    }

    &__field-container {
      background-color: #f6f6f7;
      color: #171c43;
    }

    &__image-container {
      display: flex;
      justify-content: flex-end;

      &--picture {
        height: 380px;
        width: 530px;
        background: transparent url('../../common/svg/house.svg') left top no-repeat;
        margin: calc(var(--eds-baseline) * 3) 0;

        &[aria-disabled='true'] {
          filter: $disabled-filter;
        }
      }
    }
  }

  &__header {
    position: sticky;
    top: calc(var(--eds-baseline) * 2);
    overflow: hidden;
    pointer-events: none;
    user-select: none;

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &.ds-grid__col--12 {
        padding: 0;
      }

      &__button.ds-button {
        border: none;
        pointer-events: auto;

        &.ds-button--color-disabled {
          background-color: transparent;
          border: none;
        }

        span.ds-button__content {
          font-size: calc(var(--eds-font-size-baseline) - 2px);
          letter-spacing: 0;
          text-transform: none;
          margin: 0;

          span.ds-icon.baseline--right {
            margin-right: calc(var(--eds-baseline));
          }

          span.ds-icon.baseline--left {
            margin-left: calc(var(--eds-baseline));
          }
        }

        &.color-danger {
          color: var(--eds-color-red-600);

          svg {
            fill: var(--eds-color-red-600);
          }
        }
      }
    }
  }

  &__list-item-title {
    font-family: var(--eds-header-font-family-bold);
    font-weight: var(--eds-font-weight-medium);
    font-size: var(--eds-heading-4);
    line-height: var(--eds-heading-4-line-height);

    margin-bottom: calc(var(--eds-baseline));
    padding: 0;
  }

  &__list-item-data {
    font-family: var(--eds-base-font-family);
    font-weight: var(--eds-font-weight-base);
    font-size: var(--eds-font-size-baseline);

    margin: 0;
    padding: 0;
  }

  &__list {
    .ds-tabnavigation {
      .ds-tabnavigation-tab-container .ds-tab-item {
        font-family: var(--eds-base-font-family);
        user-select: none;
      }

      &.ds-tabnavigation--disabled .ds-tabnavigation-tab-container .ds-tab-item {
        border-color: $disabled-color;
        pointer-events: none;
      }
    }

    &__navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__buttons {
        &__index.ds-button {
          background-color: transparent;
          border: none;
          font-size: var(--eds-font-size-baseline);
          font-weight: var(--eds-font-weight-bold);
          padding: 0;
          pointer-events: none;
          user-select: none;
        }

        &__page.ds-button {
          background-color: transparent;
          border: none;
          font-size: var(--eds-font-size-baseline);
          font-weight: var(--eds-font-weight-bold);
          padding: 0;
        }
      }

      &__dropdown {
        padding: 0;
        user-select: none;

        &[aria-disabled='true'],
        span[aria-disabled='true'] {
          color: $disabled-color;
        }

        div.ds-dropdown button {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }

    &__table {
      margin: calc(var(--eds-baseline) * 2) 0;
      padding: calc(var(--eds-baseline) * 2) 0;

      &__button.ds-button--icon-only.ds-button--size-l {
        border: none;
        padding: 0;
        min-height: calc(var(--eds-baseline) * 3);
        min-width: calc(var(--eds-baseline) * 3);
      }

      &__button--active,
      &__button--inactive {
        &.ds-button--size-m {
          &.ds-button--color-disabled,
          &.ds-button--color-link,
          &.ds-button--icon-only {
            background-color: transparent;
            border: none;
            min-width: unset;
            min-height: unset;
            padding: 0;
          }
        }
      }

      &__button--active.ds-button--color-link .ds-icon svg {
        fill: $ds-color__blue-600;
      }

      &__button--inactive.ds-button--color-link .ds-icon svg {
        fill: $disabled-border-color;
      }

      &__button--label {
        background-color: transparent;
        border-width: 0;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: -0.25px;
        padding: 0;

        span {
          color: $ds-color__blue-600;

          &:hover {
            text-decoration: underline;
          }

          &[aria-disabled='true'] {
            color: $disabled-color;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      &--disabled {
        button {
          pointer-events: none;
        }

        td,
        .label {
          color: $disabled-color;
        }
      }
    }
  }

  &__section-label__description.ds-grid__row {
    margin-bottom: calc(var(--eds-baseline) * 4);
  }

  &__thank-you {
    font-family: var(--eds-base-font-family);

    &__h2 {
      font-size: calc(4.5 * var(--eds-baseline));
      color: $ds-color__brand-blue;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers the entire content block */
      margin: 0 auto; /* Centers the block on the page */
    }

    &__paragraph-container {
      margin: 0 auto; /* Centers this inner container within the main block */
    }

    &__paragraph-row {
      gap: 16px; /* Adds spacing between icon and paragraph */
      margin-bottom: 16px; /* Adds vertical spacing between rows */
    }

    &__picture {
      height: 380px;
      width: 530px;
      background: transparent url('../../common/svg/thank-you.svg') center center no-repeat;
      margin: calc(var(--eds-baseline) * 3) 0;
    }
  }

  &__title {
    &.ds-grid__row {
      &.reset-top-margin {
        margin-top: 0;
      }

      margin-top: calc(-1 * $top-bar-height);
      margin-bottom: calc(var(--eds-baseline) * 4);
      max-height: calc(var(--eds-baseline) * 8);
      min-height: calc(var(--eds-baseline) * 8);
    }

    &__content {
      &__center {
        display: flex;
        align-items: center;

        &__load {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding-right: calc(var(--eds-baseline) * 2);

          &__spinner {
            padding: calc(var(--eds-baseline) * 0.75) calc(var(--eds-baseline));
          }

          &__text {
            color: var(--eds-color-grey-700);
            font-family: var(--eds-header-font-family-medium);
            font-size: calc(var(--eds-baseline) * 2.5);
            font-weight: 500;
            margin: 0;
            padding: 0 calc(var(--eds-baseline));
          }
        }

        &__logo {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding-right: calc(var(--eds-baseline) * 2);

          pre {
            color: $disabled-color;
            font-size: calc(var(--eds-font-size-baseline) - 4px);
            line-height: calc(var(--eds-font-size-baseline) - 4px);
            letter-spacing: -0.5px;
            margin: 0;
          }

          .ds-logo--primary {
            max-height: $top-bar-height;
            max-width: calc($top-bar-height * 72 / 32);

            &[aria-disabled='true'] svg {
              fill: $disabled-color;
            }
          }
        }

        &__name {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          padding-left: calc(var(--eds-baseline) * 2);

          border-left: 2px solid $ds-color__secondary-blue-400;
          color: $ds-color__brand-blue;

          &[aria-disabled='true'] {
            border-color: $disabled-border-color;
            color: $disabled-color;
          }

          & > span {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:first-of-type {
              padding-top: calc(var(--eds-baseline) / 4);
            }

            &:last-of-type {
              padding-bottom: calc(var(--eds-baseline) / 4);
            }
          }

          &__account {
            font-size: var(--eds-font-size-baseline);
            font-weight: var(--eds-font-weight-bold);
          }

          &__form {
            font-size: calc(var(--eds-font-size-baseline) - 1px);
            font-weight: var(--eds-font-weight-base);
          }

          .ds-button.ds-button--size-s {
            padding: calc(var(--eds-baseline) * 0.375) calc(var(--eds-baseline));
            line-height: unset;
            min-height: unset;
            min-width: unset;

            &.ds-button--color-disabled {
              background-color: transparent;
              border-color: transparent;
            }

            &.ds-button--color-link {
              color: $ds-color__brand-blue;

              .ds-icon svg {
                fill: $ds-color__brand-blue;
              }
            }
          }
        }
      }

      &__notifications {
        flex: 1;

        &.ds-notification {
          padding: calc(var(--eds-baseline) * 3) calc(var(--eds-baseline) * 3);
          margin: 0;

          .ds-notification-closebutton,
          .ds-notification-content,
          .ds-notification-icon {
            align-self: center;
          }
        }
      }
    }
  }

  &__view {
    .autocomplete-root {
      margin-bottom: calc(var(--eds-baseline) * 3);
    }

    .label--mandatory {
      label.ds-input--labelarea-label,
      label.ds-label,
      span.ds-selectgroup--labelarea-label-content {
        &::after {
          content: '*';
          color: $ds-color__red-600;
        }
      }
    }

    @each $type in email, field, int, tel, text, time {
      &__input__#{$type} {
        display: flex;
        justify-content: flex-start;
      }
    }

    &__input__field,
    &__input__email {
      .ds-input {
        width: 100%;
      }
    }

    &__input__textarea {
      .ds-input--inputarea textarea {
        resize: none;
      }
    }

    &__input__text {
      margin: calc(var(--eds-baseline) * 2) 0;

      .ds-input {
        width: 100%;
      }
    }

    &__breadcrumbs {
      &__grid {
        display: grid;
        gap: calc(var(--eds-baseline) * 1.5) calc(var(--eds-baseline) * 6);
        grid-template-columns: repeat(auto-fit, calc(var(--eds-baseline) * 4));
        justify-content: center;
      }

      &.ds-grid__row {
        font-weight: var(--eds-font-weight-bold);
        line-height: 1rem;
        padding: calc(var(--eds-baseline) * 5) 0;
        pointer-events: none;
        user-select: none;

        .ds-button {
          border: none;
          border-radius: calc(var(--eds-baseline) * 2);
          padding: 0;
          min-height: calc(var(--eds-baseline) * 4);
          min-width: calc(var(--eds-baseline) * 4);

          &.breadcrumb--incomplete {
            background-color: $ds-color__blue-200;
            color: $ds-color__brand-blue;

            &.ds-button--color-disabled {
              background-color: var(--eds-color-grey-200);
              color: var(--eds-color-grey-400);
            }
          }
        }
      }
    }

    &__choices {
      margin-bottom: calc(var(--eds-baseline) * 4);

      .ds-datepicker-wrapper {
        flex-direction: unset;
      }

      .ds-selectgroup__list label,
      .ds-table tr {
        outline-style: none;
        transition: background-color $transition-time;
      }

      &__narrow {
        &.ds-grid__col--6,
        &.ds-grid__col--9,
        &.ds-grid__col--12 {
          padding: 0;
        }

        & > .ds-grid__row {
          padding: 0 0 calc(var(--eds-baseline) * 2) 0;
        }
      }
    }

    &__footer {
      bottom: calc(var(--eds-baseline) * 4);
      pointer-events: none;
      position: sticky;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-top: calc(var(--eds-baseline) * 16);

        button.ds-button {
          min-width: calc(var(--eds-baseline) * 15);
          pointer-events: auto;
          user-select: none;
        }
      }
    }

    &__list-of-objects-labelarea {
      margin-bottom: calc(var(--eds-baseline) * 2);
      font-weight: var(--eds-font-weight-base);

      label {
        font-weight: var(--eds-font-weight-bold);
        font-size: calc(1.75 * var(--eds-baseline));
        line-height: calc(2.5 * var(--eds-baseline));
      }
    }

    &__list-of-objects-table {
      .ds-input {
        margin: 0 calc(var(--eds-baseline));

        .ds-input--inputarea-input {
          font-size: unset;
          padding: calc(var(--eds-baseline) * 0.5) calc(var(--eds-baseline));
          text-align: right;
        }
      }

      &__cell-disabled {
        color: $disabled-color;
      }

      &__cell-editable {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__cell-readonly {
        margin: calc(var(--eds-baseline)) 0;

        & > div {
          font-size: calc(calc(var(--eds-font-size-baseline) * 0.875));
          margin-top: calc(var(--eds-baseline));
        }
      }
    }

    &__summary {
      hr {
        margin: calc(var(--eds-baseline) * 4) 0 0 0;
        border-color: var(--eds-color-grey-400);
        border-top: none;
      }

      h4 {
        padding-left: calc(var(--eds-baseline) * 2);
        padding-right: calc(var(--eds-baseline) * 2);
      }

      &__other-contacts.ds-grid__row {
        justify-content: space-between;
      }

      &__product-table {
        &.ds-table {
          margin-top: calc(var(--eds-baseline) * 4);
        }

        tbody > tr > td > &__bold {
          font-weight: bold;
          margin: calc(var(--eds-baseline)) 0;
        }
      }
    }
  }
}
