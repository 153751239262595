@import '../../common/style/styleguide';

.of-shopping-basket-wrapper {
  .of-shopping-basket-content {
    .of-alert-disclaimer {
      .ds-disclaimer {
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;

        h5 {
          padding-top: 1px;
        }

        h5,
        li {
          color: $ds-color__red-600;
        }
      }

      .of-error-link {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .of-error-li {
        list-style-type: disc;

        .ds-link .ds-icon {
          width: 24px;
          vertical-align: top;
          margin-top: -3px;
        }

        .ds-link-last-object .ds-icon {
          margin-left: 2px;
        }
      }

      .ordered-list {
        list-style-type: auto;
      }
    }

    .of-error {
      .ds-disclaimer {
        border-left: solid 2px $ds-color__red-600;
      }
    }

    .of-warning {
      .ds-disclaimer {
        border-left: solid 2px $ds-color__orange-700;
        max-width: fit-content;
      }
      justify-self: flex-end;
    }
  }

  @media only screen and (max-width: $breakpoint-m) {
    .of-shopping-basket-content {
      .of-alert-disclaimer {
        .of-error-link {
          vertical-align: text-top;
        }
      }
    }
  }

  @media only screen and (min-width: $breakpoint-m-max-width) {
    .of-shopping-basket-content {
      .of-warning {
        .ds-disclaimer {
          white-space: nowrap;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .of-shopping-basket-content {
      .of-shoppingbasket-warning-container {
        display: flex;
      }

      .of-notification-row {
        .ds-shoppingcart__product-quantity {
          display: none;
        }
        .ds-shoppingcart__product-image {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-width: 1201px) {
    .of-shopping-basket-content {
      .of-notification-row {
        .ds-shoppingcart__product-details {
          width: 31em;
        }
      }

      .ds-shoppingcart-wrapper {
        .of-notification-row {
          .ds-shoppingcart__product-price-options:empty {
            display: none;
          }
          .ds-shoppingcart__product-image {
            height: 0;
          }
        }
      }
    }
  }
}
